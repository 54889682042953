<template>
  <div class="container">
    <!-- PART1 文献识别 开始 -->
    <div class="top">
      <!-- 一 搜索框和高亮部分 -->
      <div class="searchHighlight">
        <!-- 1 左侧搜索框 -->
        <div class="left_inputBox" style="margin: 0 0 10px 0;">
          <!-- 标题 -->

          <span style="margin: 30px 0 20px 0;height: 10px; display: inline-block">
            <!-- <el-tooltip
              class="item"
              effect="dark"
              content="使用引导"
              placement="bottom"
            >
              <i class="el-icon-question" @click="guide"></i> </el-tooltip
            > -->
            <!-- 需求识别: -->
            </span
          >
          <i @click="delete_box">
            <!-- <img src="../../../assets/imgs/删除.png" alt="" /> -->
          </i>
          <div class="left_inputtab">
            
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="输入文字" name="first">输入文字</el-tab-pane>
              <el-tab-pane label="上传图片" name="second">上传图片</el-tab-pane>
            </el-tabs>
          </div>
          <!-- 搜索框 -->
          <el-input
            v-show="activeName == 'first'"
            type="textarea"
            :autosize="{ minRows: 8, maxRows: 8 }"
            v-model="textarea"
            @input="search($event)"
            :placeholder="placeholder"
            :clearable="true"
            style="padding: 0 10px; border: none; padding: 0"
            resize="none"
          >
          </el-input>
          <!--  action="https://ql-test.newacademic.net/api/literature/ImageOcr/" -->
          <el-upload
            v-show="activeName == 'second'"
            style="padding: 0 10px; border: none; padding: 0; width: 100%"
            class="upload-demo"
            drag
            ref="upload"
            name="img"
            action="https://xcx.newacademic.net/api/literature/ImageOcr/"
            :headers="headers"
            :file-list="fileList"
            :on-success="succesfile"
            :auto-upload="true"
            :show-file-list="false"
            list-type="picture"
            accept=".jpg,.png,.gif,.jpeg"
            multiple
          >
            <!-- action="https://xcx.newacademic.net/api/literature/ImageOcr/" -->
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
          <div v-if="showerror" style="margin: 10px auto; text-align: center">
            <i class="el-icon-warning" style="color: #efb336"></i
            >无法识别图片中的文字内容
          </div>
          <div v-if="textarea && activeName == 'second'" style="margin: 10px auto; text-align: center">
            <i class="el-icon-success" style="color: #67c23a"></i>图片识别成功
          </div>
          <!-- <textarea
            class="textarea"
            name=""
            id=""
            cols="30"
            rows="10"
          ></textarea> -->
        </div>
        <!-- <div style="display: flex; align-items: center">
          <div style="position: relative; top: 25px">
            <i style=" font-size: 18px;font-weight: 700;" class="el-icon-d-arrow-right"></i>
          </div>
        </div> -->
        <!-- 2 右侧高亮框 -->
        <!-- <div class="right_highlightBox">
         
          <span style="margin: 30px 0 20px 0; display: inline-block"
            >识别对比:</span
          >
          
          <div class="highlightInput" v-html="highlights"></div>
        </div> -->
      </div>
      <!-- 二 识别按钮 -->
      <div
        style="
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 13%;
        "
        v-if="activeName == 'first'"
      >
        <el-button class="btn" @click="identification" round>识 别</el-button>
      </div>
    </div>
    <!-- PART1 文献识别 结束 -->

    <!-- PART2 识别结果 开始 -->
    <div class="bottom" v-bind:style="{ minHeight: Height + 'px' }">
      <!-- 表格部分 -->
      <!-- <p class="notices">
        <img src="../../../assets/imgs/yellow.png" alt="" />
        <span>注意：</span>
      </p> -->
      <div
        v-if="notices"
        v-bind:style="{ minHeight: Height + 'px' }"
        style="display: flex; align-items: center"
      >
        <div class="note_fu" style="width: 100%">
          <div class="note_fu01">
            <el-divider content-position="left">注意事项</el-divider>
            <div class="note">
              <p>
                <img src="../../../assets/imgs/yellow.png" alt="" />
                <span
                  >建议每次提交需求有明显的标识符，例如：PMID：*****   ISBN：******  ，有明显的标识符，会大大提高识别的准确率</span
                >
              </p>
              <p>
                <img src="../../../assets/imgs/yellow.png" alt="" />
                <span
                  >如果文献类型是"其他"，请您首先确定"需求内容"的准确性和完整性，如果没有问题，请修改正确的"文献类型"这样也会大大提高识别的准确率</span
                >
              </p>
              <p>
                <img src="../../../assets/imgs/yellow.png" alt="" />
                <span
                  >图片上传支持jpg、png、gif、jpeg，{注意：请保证图片的清晰度，不然会识别有误}</span
                >
              </p>
              <p>
                <img src="../../../assets/imgs/yellow.png" alt="" />
                <span
                  >如果{识别的文章有误}或者需要{修改识别的信息}，请选择选择"编辑"进行修改，手机端需要在识别以后左滑呼出“编辑”功能</span
                >
              </p>
              <p>
                <img src="../../../assets/imgs/yellow.png" alt="" />
                <span
                  >注意：1.{如果需要文章的"其他信息"请先点击"识别"}，2.{然后请点击"备注"添加其他的信息}，其他信息是指：补充材料、正式版等</span
                >
              </p>
              <!-- <p>
                <img src="../../../assets/imgs/yellow.png" alt="" />
                <span>如对识别结果不准确，可在识别结果列表出进行手动修改</span>
              </p>
              <p style="position: relative; top: -8px">
                <img
                  style="position: relative; top: 13px"
                  src="../../../assets/imgs/yellow.png"
                  alt=""
                />
                <span
                  >识别结果
                  <span class="col" style="color: #5eb183">绿色</span>
                  代表需求具有唯一属性能够精确定位；<span
                    class="col"
                    style="color: rgb(231, 160, 33)"
                    >橙色</span
                  >
                  代表文献可能存在相似文章；<span
                    class="col"
                    style="color: rgb(231, 101, 100)"
                    >红色</span
                  >
                  代表青梨未能识别出该条需求</span
                >
              </p> -->
            </div>
          </div>
        </div>
      </div>

      <el-table
        v-if="!notices"
        ref="multipleTable"
        tooltip-effect="dark"
        :row-class-name="tableRowClassName"
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @select="selectAll"
        :cell-style="columnStyle"
        height="calc(100vh - 580px)"
      >
        <el-table-column type="selection" :selectable="selectable" width="40">
          <!-- :selectable="selectable" -->
        </el-table-column>
        <el-table-column label="序号" width="50" type="index"></el-table-column>
        <el-table-column label="提交状态" width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.submit == 0" style="color: #909399"
              >未提交</span
            >
            <span v-if="scope.row.submit == 1" style="color: #409eff"
              >提交成功</span
            >
            <span v-if="scope.row.submit == 2" style="color: #e6a23c"
              >重复提交</span
            >
            <span v-if="scope.row.submit == 3" style="color: #f56c6c"
              >今日非中文书已达上限</span
            >
            <span v-if="scope.row.submit == 4" style="color: #f56c6c"
              >今日中文书已达上限</span
            >
            <span v-if="scope.row.submit == 6" style="color: #f56c6c"
              >需求不能为空</span
            >
            <span v-if="scope.row.submit == 5" style="color: #f56c6c"
              >提交失败</span
            >
            <span v-if="scope.row.submit == 7" style="color: #f56c6c"
              >提交数量已达上限</span
            >
            <span
              v-if="
                scope.row.submit !== 0 &&
                scope.row.submit !== 1 &&
                scope.row.submit !== 2 &&
                scope.row.submit !== 3 &&
                scope.row.submit !== 4 &&
                scope.row.submit !== 5 &&
                scope.row.submit !== 6 &&
                scope.row.submit !== 7
              "
              style="color: #f56c6c"
              >{{ scope.row.submit }}</span
            >
            <!-- <el-tag type="info" v-if="scope.row.submit == 0">未提交</el-tag>
            <el-tag type="success" v-if="scope.row.submit == 1"
              >提交成功</el-tag
            >
            <el-tag type="warning" v-if="scope.row.submit == 2"
              >重复提交</el-tag
            >
            <el-tag type="danger" v-if="scope.row.submit == 3"
              >今日非中文书已达上限</el-tag
            >
            <el-tag type="danger" v-if="scope.row.submit == 4"
              >今日中文书已达上限</el-tag
            >
            <el-tag type="danger" v-if="scope.row.submit == 6">需求不能为空</el-tag>
            <el-tag type="danger" v-if="scope.row.submit == 5">提交失败</el-tag> -->
            <!-- <span>{{ scope.row.text }}</span> -->
          </template></el-table-column
        >
        <el-table-column label="" width="150">
          <template slot="header" slot-scope="scope">
            <span style="margin-right: 10px; display: inline-block"
              >文献类型</span
            >
            <el-popover placement="top" width="200" trigger="hover">
              <!-- <h2>注意事项</h2> -->
              <p style="line-height: 20px">
                <span>注意: </span
                ><span style="color: #f86b52"
                  >若文献类型识别为中文书或英文书,则该文献类型不能进行修改</span
                >
              </p>
              <!-- <p style="text-indent:2em;">1、若文献类型识别为非中文书或英文书,则该文献类型不能进行修改</p> -->
              <i slot="reference" class="el-icon-question"></i>
            </el-popover>
          </template>
          <template slot-scope="scope" style="background: #d9a673">
            <el-dropdown
              size="mini"
              split-button
              type="primary"
              :disabled="
                (scope.row.type_code !== 'zhBook' &&
                  scope.row.type_code !== 'enBook') ||
                scope.row.revise
                  ? false
                  : true
              "
              :class="
                scope.row.type_code == null ? 'null' : scope.row.type_code
              "
            >
              <span v-if="scope.row.type_code == 'zhBook'">中文书</span>
              <span v-if="scope.row.type_code == 'enBook'">英文书</span>
              <span v-if="scope.row.type_code == 'PMID'">PMID</span>
              <span v-if="scope.row.type_code == 'PMCID'">PMCID</span>
              <span v-if="scope.row.type_code == 'Doi'">DOI</span>
              <span v-if="scope.row.type_code == 'Other'">其他</span>
              <span v-if="scope.row.type_code == null">其他</span>
              <span v-if="scope.row.type_code == 'Url'">链接地址</span>
              <span v-if="scope.row.type_code == 'zhDoc'">中文文献</span>
              <span v-if="scope.row.type_code == 'enDoc'">英文文献</span>
              <span v-if="scope.row.type_code == 'patent'">专利</span>
              <span v-if="scope.row.type_code == 'standard'">标准</span>
              <!-- <div>
              <el-tag class="tag_hover">
                <span v-if="scope.row.type_code == 'zhBook'">中文书</span>
                <span v-if="scope.row.type_code == 'enBook'">英文书</span>
                <span v-if="scope.row.type_code == 'PMID'">PMID</span>
                <span v-if="scope.row.type_code == 'PMCID'">PMCID</span>
                <span v-if="scope.row.type_code == 'Doi'">DOI</span>
                <span v-if="scope.row.type_code == 'Other'">其他</span>
                <span v-if="scope.row.type_code == null">其他</span>
                <span v-if="scope.row.type_code == 'Url'">链接地址</span>
                <span v-if="scope.row.type_code == 'zhDoc'">中文文献</span>
                <span v-if="scope.row.type_code == 'enDoc'">英文文献</span>
                <span v-if="scope.row.type_code == 'patent'">专利</span>
                <span v-if="scope.row.type_code == 'standard'"
                  >标准</span
                > </el-tag
              >
              <i
                @click="editSelect(scope.row, scope.$index)"
                class="el-icon-edit text_edit"
                v-if="
                  scope.row.type_code !== 'zhBook' &&
                  scope.row.type_code !== 'enBook'
                "
              ></i>
            </div> -->

              <!-- <span v-if="scope.row.type_code == 'standard'">标准</span> -->
              <!-- <span v-if="scope.row.type_code == 'ISBN'">ISBN</span> -->
              <el-dropdown-menu slot="dropdown">
                <div
                  v-if="
                    (scope.row.type_code !== 'zhBook' &&
                      scope.row.type_code !== 'enBook') ||
                    scope.row.revise
                  "
                >
                  <el-dropdown-item
                    v-for="(item, index) in type"
                    :key="index"
                    @click.native="types(scope.row.index, item.type)"
                    ><span
                      v-if="
                        (scope.row.type_code !== 'zhBook' &&
                          scope.row.type_code !== 'enBook') ||
                        scope.row.revise
                      "
                      >{{ item.book_type }}</span
                    ></el-dropdown-item
                  >
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <el-tag
              type="success"
              slot="reference"
              slot-scope="scope"
              v-if="scope.row.type_code !== 'zhBook'"
              >中文书</el-tag
            ></template
          >

          <!-- <template slot-scope="{ row }">
            <el-popover
              popper-class="pop"
              placement="bottom"
              width="10"
              style="min-width: none"
              trigger="hover"
            >
              <ul style="list-style: none; margin: 0; padding: 0">
                <li><p @click="userType(row, 'zhBook')">中文书</p></li>
                <li><p @click="userType(row, 'enBook')">英文书</p></li>
                <li><p @click="userType(row, 'PMID')">PMID</p></li>
                <li><p @click="userType(row, 'PMCID')">PMCID</p></li>
                <li><p @click="userType(row, 'Doi')">DOI</p></li>
              </ul>
              <el-tag
                type="success"
                slot="reference"
                v-if="row.type_code == 'zhBook'"
                >中文书</el-tag
              >
              <el-tag
                type="success"
                slot="reference"
                v-if="row.type_code == 'enBook'"
                >英文书</el-tag
              >
              <el-tag
                type="success"
                slot="reference"
                v-if="row.type_code == 'PMID'"
              >
                PMID</el-tag
              >
              <el-tag
                type="success"
                slot="reference"
                v-if="row.type_code == 'PMCID'"
                >PMCID</el-tag
              >
              <el-tag
                type="success"
                slot="reference"
                v-if="row.type_code == 'Doi'"
                >DOI</el-tag
              >
              <el-tag
                type="success"
                slot="reference"
                v-if="row.type_code == 'Other'"
                >其他</el-tag
              >
            </el-popover>
          </template> -->
        </el-table-column>
        <el-table-column label="需求内容">
          <template slot-scope="scope">
            <span v-if="edit_box !== scope.$index">
              <span
                v-if="
                  scope.row.type_code == 'Doi' ||
                  scope.row.type_code == 'ISBN' ||
                  scope.row.type_code == 'PMID' ||
                  scope.row.type_code == 'Url' ||
                  scope.row.type_code == 'zhBook' ||
                  scope.row.type_code == 'enBook'
                "
                style="color: white; background-color: #5eb183"
                >{{ scope.row.text }}</span
              >
              <span
                v-else-if="
                  scope.row.type_code == 'zhDoc' ||
                  scope.row.type_code == 'enDoc'
                "
                style="color: white; background-color: #f4cc84"
                >{{ scope.row.text }}</span
              >
              <span v-else style="color: white; background-color: #fa9a99">{{
                scope.row.text
              }}</span>

              <i
                @click="resultsEdit01(scope.row, scope.$index)"
                class="el-icon-edit text_edit"
              ></i
            ></span>
            <div
              v-if="edit_box == scope.$index"
              style="max-width: 300px; display: flex"
            >
              <el-input
                ref="id"
                size="mini"
                placeholder="请输入需求内容"
                v-model="outcome"
                @blur="edit_needs(scope.row, scope.$index)"
              >
              </el-input>
              <el-button
                size="mini"
                slot="reference"
                class="edit_suc"
                icon="el-icon-success"
                style="color: #73d973"
                @click="edit_needs(scope.row, scope.$index)"
              ></el-button>
              <!-- <el-popconfirm
                @confirm="edit_needs(scope.row, scope.$index)"
                @cancel="edit_cancel"
                title="确定修改该需求内容吗？"
              >
                <el-button
                  size="mini"
                  slot="reference"
                  class="edit_suc"
                  icon="el-icon-success"
                  style="color: #73d973;"
                ></el-button>
              </el-popconfirm> -->
            </div>
          </template></el-table-column
        >
        <el-table-column label="备注" width="">
          <template slot-scope="scope">
            <span v-if="scope.row.remark !== ''"
              >{{ scope.row.remark }}
              <i
                @click="remark(scope.$index, scope.row)"
                class="el-icon-edit text_edit"
              ></i
            ></span>
            <span
              v-if="scope.row.remark == ''"
              class="addRemark"
              @click="remark(scope.$index, scope.row)"
              >添加<i class="el-icon-edit text_edit"></i
            ></span> </template
        ></el-table-column>
        <el-table-column label="操作" align="center" width="100">
          <template slot-scope="scope">
            <el-button
              size="mini"
              round
              style="
                background-image: linear-gradient(to bottom, #b14a75, #b14a75);
                color: white;

                margin: 0 0 10px 0;
              "
              v-if="scope.row.submit !== 1 && scope.row.submit !== 2"
              @click="resultsEdit(scope.row, scope.$index)"
              >编辑</el-button
            >
          </template></el-table-column
        >
        <!-- <el-table-column prop="name" label="紧急程度" width="150">
          <template slot-scope="scope">
            <el-dropdown size="mini" split-button type="primary">
              {{ scope.row.priority }}
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in extent"
                  :key="item"
                  @click.native="revise(scope.row.index, item)"
                  >{{ item }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown></template
          ></el-table-column
        > -->
        <template slot="empty">
          <div class="imgs">
            <img
              src="../../../assets/imgs/zanwushuj.png"
              alt=""
              style="width: 50%"
            />
          </div>
        </template>
      </el-table>
      <!-- 按钮部分 -->

      <div
        class="but"
        style="padding: 20px 0; text-align: center; margin-right: 50px"
        v-if="identify"
      >
        <el-button
          style="background: #fa9a99; font-weight: 600"
          @click="empty"
          round
          >清空结果</el-button
        >
        <el-button
          style="
            color: #b14a75;
            border: #b14a75 solid 1px;
            background: none;
            font-weight: 600;
          "
          @click="merge_demand"
          round
          >需求合并</el-button
        >
        <el-button style="font-weight: 600" @click="submit" round
          >提交</el-button
        >
      </div>
    </div>
    <!--  -->
    <!-- PART2 识别结果 结束 -->
    <el-dialog
      :title="dialog_tit"
      :visible.sync="dialogFormVisible"
      :width="'500px'"
    >
      <el-form :model="form">
        <el-form-item label="需求类型" :label-width="formLabelWidth">
          <el-select
            v-model="form.text"
            placeholder="请选择"
            :disabled="
              or_select == 'zhBook' || or_select == 'enBook' ? true : false
            "
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="需求内容" :label-width="formLabelWidth">
          <el-input v-model="form.textEdit" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="扩展信息" :label-width="formLabelWidth">
          <el-input
            placeholder="请输入拓展信息"
            v-model="form.extend"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item label="添加备注" :label-width="formLabelWidth">
          <el-input
            placeholder="请输入备注"
            v-model="remarkText"
            autocomplete="off"
            type="textarea"
            class="textarea_css_remark"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item label="常用备注" :label-width="formLabelWidth">
          <el-button size="mini" round @click="addText('正式版')"
            >正式版</el-button
          >
          <el-button size="mini" round @click="addText('补充材料')"
            >补充材料</el-button
          >
          <el-button size="mini" round @click="addText('查收查引')"
            >查收查引</el-button
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="添加备注"
      :visible.sync="dialogFormVisible01"
      :width="'500px'"
    >
      <el-form>
        <el-form-item label="添加备注" :label-width="formLabelWidth">
          <el-input
            placeholder="请输入备注"
            v-model="remarkText"
            autocomplete="off"
            type="textarea"
            class="textarea_css_remark"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item label="常用备注" :label-width="formLabelWidth">
          <el-button size="mini" round @click="addText('正式版')"
            >正式版</el-button
          >
          <el-button size="mini" round @click="addText('补充材料')"
            >补充材料</el-button
          >
          <el-button size="mini" round @click="addText('查收查引')"
            >查收查引</el-button
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible01 = false">取 消</el-button>
        <el-button type="primary" @click="deterRemark">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import { Identify, submit } from "@/Api/manage";
import { uploadocr } from "@/Api/upload";
import store from "@/store";
import $ from "jquery";
export default {
  data() {
    return {
      textarea1: "",
      textarea2: "",
      textarea: "",
      // placeholder:
      //   "示例如下：\nDOI:10.31486/toj.20.0025\nPMID:34239371\nISBN:9782954099477",
      placeholder:`可批量查询文献，每条需求以换行结束，需求示例如下：\nDOI:10.31486/toj.20.0025\nPMID:34239371\nISBN:9782954099477`,
      txt: {
        text: "",
      },
      // text: "",
      highlights: "",
      identify: false,
      tableData: [],
      figures: [],
      fileList: [],
      label: "",
      extent: ["一般", "优先"],
      type: [
        {
          book_type: "中文书",
          type: "zhBook",
        },
        {
          book_type: "英文书",
          type: "enBook",
        },
        {
          book_type: "DOI",
          type: "Doi",
        },
        {
          book_type: "PMID",
          type: "PMID",
        },
        {
          book_type: "PMCID",
          type: "PMCID",
        },
        {
          type: "Other",
          book_type: "其他",
        },
        {
          type: "Url",
          book_type: "链接地址",
        },
        {
          type: "zhDoc",
          book_type: "中文文献",
        },
        {
          type: "enDoc",
          book_type: "英文文献",
        },
        {
          type: "patent",
          book_type: "专利",
        },
        {
          type: "standard",
          book_type: "标准",
        },
      ],
      multipleSelection: [],
      chen: "",
      chose: [],
      limitNum: 10, //限制数量
      Height: 0,
      dialogFormVisible: false,
      form: {
        text: "",
        textEdit: "",
        extend: "",
      },
      formLabelWidth: "100px",
      table_index: "",
      options: [
        {
          value: "zhBook",
          label: "中文书",
        },
        {
          value: "enBook",
          label: "英文书",
        },
        {
          value: "PMID",
          label: "PMID",
        },
        {
          value: "PMCID",
          label: "PMCID",
        },
        {
          value: "Doi",
          label: "DOI",
        },
        {
          value: "Other",
          label: "其他",
        },
        {
          value: "Url",
          label: "链接地址",
        },
        {
          value: "zhDoc",
          label: "中文文献",
        },
        {
          value: "enDoc",
          label: "英文文献",
        },
        {
          value: "patent",
          label: "专利",
        },
        {
          value: "standard",
          label: "标准",
        },
      ],
      or_select: "",
      edit_original: "",
      originally: "",
      dialogFormVisible01: false,
      remarkText: "",
      textIndex: "",
      message: "",
      edit_box: null,
      outcome: "",
      notices: true,
      headers: {
        token: localStorage.getItem("token"),
      },
      activeName: "first",
      showerror: false,
      dialog_tit: "修改需求内容",
    };
  },
  async created() {
    // 首页跳转进入
    let textarea = sessionStorage.getItem('textarea')
    if(textarea) {
      this.activeName = 'first'
      this.textarea = textarea;
      sessionStorage.removeItem('textarea')
      await this.search(textarea)
      this.identification()
      return
    }
    let textareaOcr = sessionStorage.getItem('textareaOcr')
    if(textareaOcr) {
      this.activeName = 'second'
      this.textarea = textareaOcr;
      sessionStorage.removeItem('textareaOcr')
      await this.search(textareaOcr, 'ocr')
      this.identification()
    }

    this.headers.token = localStorage.getItem("token");
    // 赋值默认值，取第一个下拉值
    this.label = "一般";
  },
  watch: {},
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 540; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 540;
    };
  },
  methods: {
    handleClick(tab, event) {
      this.textarea = ''
      console.log(tab, event);
    },
    //ocr 识别回调
    async succesfile(res) {
      console.log(res.data[1]);
      if (res.error_code == 0) {
        if (res.data[1] == null || res.data[1].length == 0) {
          this.showerror = true;
        } else {
          this.textarea = "";
          res.data[1].map((item) => {
            this.textarea += item.words + "\n";
          });
          this.showerror = false;
          await this.search(this.textarea, "ocr");
          this.identification();
        }
      } else {
        this.showerror = true;
      }
    },
    // 识别
    search(event, ocr) {
      return new Promise((resolve, reject) => {
        // console.log(event);
        this.text = event;
        Identify({ text: event })
          .then((res) => {
            if (res.data.error_code == 0) {
              var highlight = "";
              var count = 1;
              res.data.data.forEach((item) => {
                var text = item.highlight;
                item.result_list.forEach((itemIn) => {
                  var number =
                    "<span style='font-weight:600'>" + count + "、</span>";
                  if (
                    itemIn.name == "Doi" ||
                    itemIn.name == "ISBN" ||
                    itemIn.name == "PMID" ||
                    itemIn.name == "链接地址" ||
                    itemIn.name == "中文书" ||
                    itemIn.name == "英文书"
                  ) {
                    text = text.replace(
                      "<b>",
                      number +
                        '<span style="white-space:pre-wrap;word-wrap : break-word ;overflow: hidden ; width:50px;white-space:wrap;margin:0;padding:0;color:white;background-color:#5eb183;line-height: 22px;">'
                    );
                    text = text.replace("</b>", "</span>");
                  } else if (
                    itemIn.name == "中文文献" ||
                    itemIn.name == "英文文献"
                  ) {
                    text = text.replace(
                      "<b>",
                      number +
                        '<span style="white-space:pre-wrap;word-wrap : break-word ;overflow: hidden ; width:50px;white-space:wrap;margin:0;padding:0;color:white;background-color:#f4cc84;line-height: 22px;">'
                    );
                    text = text.replace("</b>", "</span>");
                  } else {
                    text = text.replace(
                      "<b>",
                      number +
                        '<span style=" white-space:pre-wrap;word-wrap : break-word ;overflow: hidden ;margin:0;padding:0;color:white;background-color:#fa9a99;line-height: 22px;">'
                    );
                    text = text.replace("</b>", "</span>");
                  }
                  count++;
                });
                highlight += text + "<br>";
                // console.log(highlight);
                this.highlights = highlight;
                console.log(res);
                this.figures = res.data.data;
                // console.log(this.tableData);
              });
              this.message = res.data.msg;
              // this.identification()
              resolve()
            }
            if (res.data.error_code == 1) {
              this.highlights = "";
              this.message = res.data.msg;
              if (ocr == "ocr") {
                this.$message({
                  message: this.message,
                  type: "warning",
                });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
        })
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    // 其他类型修改下标
    columnStyle({ row, column, rowIndex, columnIndex }) {
      // console.log(row.type_code)
      // console.log(column)
      // if (row.type_code == "Other") {
      //背景色就改变了都是列数的下标
      // return "background: rgb(255, 92, 92,0.5);";
      // }
    },
    // 识别检索内容
    identification() {
      // if (this.identify == false) {
      //   this.identify = true;
      // }

      this.edit_box = null;
      if (this.message == "") {
        if (this.textarea == "") {
          this.$message({
            message: "请输入需求内容",
            type: "warning",
          });
        } else {
          this.$message({
            message: "请输入需求内容",
            type: "warning",
          });
        }
      } else if (this.message !== "文本分类完成！") {
        this.$message({
          message: this.message,
          type: "warning",
        });
      } else {
        this.notices = false;
        this.tableData = [];
        this.figures.forEach((item) => {
          let liter = {
            type_code: item.result_list[0].code,
            text: item.result_list[0].text,
            original_text: item.highlight,
            priority: "一般",
            supplement: "",
            remark: "",
            submit: 0,
          };
          this.tableData.push(liter);
        });
        // console.log(this.tableData);
        this.chose = [];
        this.tableData.forEach((item, index) => {
          if (item.type_code !== "Other") {
            this.chose.push(index);
          }
        });
        // console.log(chose);
        if (this.chose.length > 10) {
          this.chose.splice(10);
        }
        // let _this = this;

        this.chose.forEach((item) => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(
              this.tableData[item],
              true
            );
          });
        });
        if (this.tableData.length > 0) {
          this.identify = true;
        } else {
          this.identify = false;
        }
      }

      // this.tableData = this.unique(this.tableData);
    },
    // 去重
    // unique(arr) {
    //   const res = new Map();
    //   return arr.filter((arr) => !res.has(arr.text) && res.set(arr.text, 1));
    // },
    // 修改文书类型
    // userType(row, comman) {
    //   console.log(row, comman);

    //   // let temp = {
    //   //   code: row.user_code,
    //   //   status: comman,
    // },
    types(index, type) {
      // console.log(index, type);
      this.$set(this.tableData[index], "revise", -1);
      this.tableData[index].type_code = type;

      // console.log(this.tableData);
    },
    // 修改紧急程度
    revise(index, item) {
      // console.log(item);
      this.tableData[index].priority = item;
      // this.label = `${item}`;
      // console.log(this.tableData);
    },
    // 多选  批量操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (this.multipleSelection.length > 10) {
        this.multipleSelection.splice(10);
        this.$message({
          message: "单次最多提交10条需求！！",
          type: "warning",
        });
        // console.log(this.multipleSelection)
      }

      // console.log(val);
      // if (val.length > 10) {
      //   this.$nextTick(() => {
      //     this.$refs.multipleTable.toggleRowSelection(
      //       this.tableData[val.length],
      //       false
      //     );
      //   });
      // }
    },
    // 全选
    selectAll(val, row) {
      // console.log(val, row);
      if (val.length > 10) {
        this.$refs.multipleTable.toggleRowSelection(
          this.tableData[row.index],
          false
        );
        this.multipleSelection.splice(10);
      } else {
        this.multipleSelection = val;
      }
      // console.log(this.multipleSelection.length)
      //

      // if (val.length > this.limitNum) {
      //     this.limitFn(val)
      //     return
      //   }
      //   this.multipleSelection = [...val];
      // console.log(val);
      // if(val.length >0){
      //   this.limitFn(val);
      // }
    },
    // // 限制数量
    // limitFn(list) {
    //   this.$refs.multipleTable.clearSelection();
    //   for (let i = 0; i < this.limitNum; i++) {
    //     this.$refs.multipleTable.toggleRowSelection(list[i]);
    //   }
    // },
    // // 判断复选框是否可以选择
    selectable(row) {
      // let index = this.tableData.findIndex(
      //   (v) => v.text === row.text
      // );
      // console.log(index)
      if (row.submit !== 1 && row.submit !== 2) {
        return true;
      } else {
        return false;
      }
      // if (this.multipleSelection.length >= this.limitNum) {

      // } else {
      //   return true;
      // }
    },
    // 清空输入框内容
    delete_box() {
      this.textarea = "";
      this.search(this.textarea);
      this.identify = false;
    },
    // 清空结果
    empty() {
      // this.identify = false;
      this.tableData = [];
      this.identify = false;
    },
    remark(i, data) {
      console.log(i, data);
      this.remarkText = data.remark;
      this.textIndex = i;
      this.dialogFormVisible01 = true;
    },
    addText(txt) {
      this.remarkText = this.remarkText + txt + ",";
    },
    deterRemark() {
      this.tableData[this.textIndex].remark = this.remarkText;
      this.dialogFormVisible01 = false;
      // this.$set(this.tableData[this.textIndex], "remark", this.remarkText);
    },
    // 提交结果
    submit() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请勾选需求任务",
          type: "warning",
        });
      }
      if (this.edit_box !== null) {
        this.$message({
          message: "请先确定需求内容的修改",
          type: "warning",
        });
      }
      // console.log(this.tableData);
      let submitDemand = [];
      let text_ = ''
      if (this.multipleSelection.length > 0 && this.edit_box == null) {
        this.multipleSelection.forEach((item, index) => {
          // this.$set(item, "id", item.index);
          let demands = {
            id: item.index,
            type_code: item.type_code,
            text: item.text,
            supplement: item.supplement,
            original_text: item.original_text,
            priority: item.priority,
            remark: item.remark,
          };
          // if (item.index) {
          submitDemand.push(demands);
          text_ =  text_+ item.text + `\n`
          // }
          // if (item.submit) {
          // this.$delete(item, "submit");
          // }
          // console.log(item);
        });
        // this.multipleSelection = []
        // console.log(text_);
        // return;
        
        let sub = {
          demand: submitDemand,
          text: text_,
        };
        submit(sub)
          .then((res) => {
            console.log(res);

            if (res.data.error_code == 1) {
              // this.$message({
              //   message: res.data.msg,
              //   type: "warning",
              // });
            }
            if (res.data.error_code == 0) {
              for (var i in res.data.data) {
                // console.log(i, ":", res.data.data[i]);
                if (res.data.data[i].msg == "需求提交成功") {
                  this.tableData[i].submit = 1;
                }
                if (res.data.data[i].msg == "今日已提交过此需求") {
                  this.tableData[i].submit = 2;
                }
                if (
                  res.data.data[i].msg == "今日您非中文书的提交数量已达上限"
                ) {
                  this.tableData[i].submit = 3;
                }
                if (res.data.data[i].msg == "今日您中文书的提交数量已达上限") {
                  this.tableData[i].submit = 4;
                }
                if (res.data.data[i].msg == "需求内容不得为空") {
                  this.tableData[i].submit = 6;
                }
                if (
                  res.data.data[i].msg == "未知原因提交失败" ||
                  res.data.data[i].msg == "需求提交过快"
                ) {
                  this.tableData[i].submit = 5;
                }
                if (res.data.data[i].msg == "提交数量已达上限") {
                  this.tableData[i].submit = 7;
                }
                if (
                  res.data.data[i].code == 1 &&
                  res.data.data[i].msg !== "提交数量已达上限" &&
                  res.data.data[i].msg !== "今日已提交过此需求" &&
                  res.data.data[i].msg !== "今日您非中文书的提交数量已达上限" &&
                  res.data.data[i].msg !== "今日您中文书的提交数量已达上限" &&
                  res.data.data[i].msg !== "需求内容不得为空" &&
                  res.data.data[i].msg !== "未知原因提交失败" &&
                  res.data.data[i].msg !== "需求提交过快"
                ) {
                  if (res.data.data[i].msg.length > 25) {
                    this.tableData[i].submit = "提交失败";
                    //             this.$message({
                    //   message: res.data.data[i].msg,
                    //   type: 'warning'
                    // });

                    this.$confirm(res.data.data[i].msg, "提示", {
                      confirmButtonText: "确定",
                      // cancelButtonText: '取消',
                      showCancelButton: false,
                      type: "warning",
                    })
                      .then(() => {})
                      .catch(() => {});
                  } else {
                    this.tableData[i].submit = res.data.data[i].msg;
                  }
                  // this.tableData[i].submit = res.data.data[i].msg;
                }
                this.$refs.multipleTable.toggleRowSelection(
                  this.tableData[i],
                  false
                );
                this.selectable(this.tableData[i]);
                // res.data.data[i];
              }
              let exists = Object.values(res.data.data).some(
                (item) => item.code === 0
              );
              if(exists){
                this.$emit("tabSub", true);
              }
              
              // console.log('a')
              // setTimeout(() => {
              //   this.$router.push({
              //     name: "Search",
              //     query: { webPage: 1 },
              //   });
              // }, 1000);
              // res.data.data.forEach((item, index) => {
              //   if (item.code == 0) {
              //     this.tableData[index].submit = 1;
              //   }
              //   if (item.code == 1) {
              //     this.tableData[index].submit = 2;
              //   }
              // });
              // console.log(this.tableData);
              // this.$message({
              //   message: "res.data.msg",
              //   type: "success",
              // });
            }
          })
          .catch((err) => {
            // console.log(err);
            // this.tableData[item.index].submit = 2;
            // this.selectable(item);
            // this.$message({
            //   message: "提交失败",
            //   type: "warning",
            // });
          });
      }

      // this.$refs.multipleTable.clearSelection();
      // console.log(this.multipleSelection)
    },
    merge_demand() {
      console.log(this.multipleSelection);
      if (this.multipleSelection.length <= 1) {
        this.$message({
          message: "请至少勾选两项需求任务",
          type: "warning",
        });
        return;
      }
      if (this.edit_box !== null) {
        this.$message({
          message: "请先确定需求内容的修改",
          type: "warning",
        });
        return;
      }
      let submitDemand = [];
      console.log(this.multipleSelection)
      if (this.multipleSelection.length > 0 && this.edit_box == null) {
        console.log(this.multipleSelection);
        let i = this.multipleSelection[0].index;
        console.log(this.tableDatai)
        console.log(this.tableData)
        console.log(this.tableData[i])
        if (this.tableData[i].type_code == "zhBook") {
          this.or_select = this.tableData[i].type_code;
        } else {
          this.or_select = "";
        }
        this.form.text = this.tableData[i].type_code;
        //  this.$set(this.tableData[i], "revise", -1);
        this.form.extend = this.multipleSelection
          .map((item) => item.supplement)
          .join("");
        this.edit_original = this.multipleSelection
          .map((item) => item.original_text)
          .join("");
        this.form.textEdit = this.multipleSelection
          .map((item) => item.text)
          .join("");
        this.remarkText = this.multipleSelection
          .map((item) => item.remark)
          .join("");
        this.originally = this.multipleSelection
          .map((item) => item.text)
          .join("");
        this.dialog_tit = "合并需求";
        this.dialogFormVisible = true;
      }
    },
    // 使用指引
    guide() {
      // this.$emit("Guide", true);
    },
    resultsEdit(data, i) {
      console.log(data);
      this.dialog_tit = "修改需求内容";
      // console.log(i)
      this.table_index = i;
      // this.outcome = this.tableData[i].text;
      // this.edit_box = i;
      // console.log(this.edit_box);
      // return;
      // console.log(this.tableData[i])
      this.form.text = this.tableData[i].type_code;
      if (this.tableData[i].type_code == "zhBook") {
        this.or_select = this.tableData[i].type_code;
      } else {
        this.or_select = "";
      }

      //  this.$set(this.tableData[i], "revise", -1);
      this.form.extend = this.tableData[i].supplement;
      this.edit_original = this.tableData[i].original_text;
      this.form.textEdit = this.tableData[i].text;
      this.remarkText = this.tableData[i].remark;
      this.originally = this.tableData[i].text;
      this.dialogFormVisible = true;
    },
    resultsEdit01(data, i) {
      const _this = this;
      setTimeout(function () {
        _this.$refs.id.focus();
      }, 1);
      // this
      console.log(data);
      // console.log(i)
      this.table_index = i;
      this.outcome = this.tableData[i].text;
      this.edit_box = i;
      console.log(this.edit_box);
      return;
      // console.log(this.tableData[i])
      this.form.text = this.tableData[i].type_code;
      if (
        this.tableData[i].type_code == "zhBook" ||
        this.tableData[i].type_code == "zhBook"
      ) {
        this.or_select = this.tableData[i].type_code;
      } else {
        this.or_select = "";
      }

      //  this.$set(this.tableData[i], "revise", -1);
      this.form.extend = this.tableData[i].supplement;
      this.edit_original = this.tableData[i].original_text;
      this.form.textEdit = this.tableData[i].text;
      this.originally = this.tableData[i].text;
      this.dialogFormVisible = true;
    },
    edit_needs(row, index) {
      this.tableData[index].text = this.outcome;
      this.edit_box = null;
    },
    edit_cancel() {
      // this.edit_box = null;
    },
    determine() {
      let i = 0;
      if (this.dialog_tit == "合并需求") {
        i = this.multipleSelection[0].index;

        let idsArray = this.multipleSelection
          .filter((item) => item.index !== i) // 过滤掉指定id的对象
          .map((item) => item.index); // 提取剩余对象的id
        console.log(idsArray);
        this.tableData = this.tableData.filter(
          (item) => !idsArray.includes(item.index)
        );
      } else {
        if (this.or_select == "") {
          this.$set(this.tableData[this.table_index], "revise", -1);
        }
        i = this.table_index;
      }
      this.tableData[i].type_code = this.form.text;
      this.tableData[i].supplement = this.form.extend;
      this.tableData[i].text = this.form.textEdit;
      this.tableData[i].original_text = this.edit_original.replaceAll(
        this.originally,
        this.form.textEdit
      );
      this.tableData[i].remark = this.remarkText;
      this.dialogFormVisible = false;
    },
  },
};
</script>


<style lang="less" scoped>
.container {
  // height: calc(100vh - 210px);
  // overflow-x: hidden;
  // overflow-y: scroll;
  // line-height: 20px;
  // text-align: left;

  .top {
    // height: 40%;
    max-height: 600px;
    background-color: #fff;
    margin-bottom: 20px;

    span {
      font-size: 16px;
      font-weight: 600;
    }
    .searchHighlight {
      display: flex;
      justify-content: center;
      // padding: 0 35px;
      padding: 0 13%;
      // border-bottom: 1px #eaeefa solid;
      .left_inputBox {
        width: 70%;
        padding-right: 2%;
        min-height: 200px;
        // border-right: 1px #eaeefa solid;
        position: relative;
        i {
          font-size: 16px;
          margin-right: 5px;
          color: rgb(109, 109, 109);
          cursor: pointer;
        }
        .left_inputtab {
          position: absolute;
          top: 15px;
          right: 50%;
          display: flex;
          width: 120px;
          margin-right: -60px;
          justify-content: center;
        }
        // i {
        //   overflow: hidden;
        //   font-size: 21px;

        //   color: rgb(204, 204, 204);
        //   position: absolute;
        //   top: 28px;
        //   right: 12px;
        //   z-index: 99;
        //   cursor: pointer;
        //   img {
        //     width: 21px;
        //     color: aqua;
        //   }
        // }
        // i:hover img {
        //   // width: 30px;
        //   transform: translateY(-50px);
        //   filter: drop-shadow(#8d8d8d 0 50px);
        // }
      }
      .right_highlightBox {
        width: 48%;
        padding-left: 2%;
        .highlightInput {
          // border: 1px solid #aaa;
          font-family: "ArialMT", "Microsoft YaHei", Arial, sans-serif,
            Helvetica, "STHeiti";
          background: #f8f8f8;
          padding: 10px;
          border-radius: 4px;
          height: 196px;
          // max-height: 450px;
          min-width: 100%;
          // margin: 5px 0px;
          line-height: 21px;
          overflow-x: hidden;
          overflow-y: auto;
        }
      }
      .el-textarea {
        /deep/ .el-textarea__inner {
          background: #f8f8f8;
          border: none;
          padding: 10px;
          font-family: "ArialMT", "Microsoft YaHei", Arial, sans-serif,
            Helvetica, "STHeiti";
        }
      }
    }
    .right {
      display: flex;
      padding: 0 30px;
    }
  }
}

.textarea {
  display: block;
  min-height: 150px;
  min-width: 100%;
  padding: 8px;
  border: 1px solid #aaa;
}
/deep/ .el-textarea__inner {
  line-height: 22px;
}
.bottom {
  // min-height: 330px;
  background-color: #fff;
  padding: 0 10%;
}
.btn {
  // background-color: #b14a75;

  background-image: linear-gradient(to bottom, #b14a75, #b14a75);
  width: 180px;
  height: 40px;
  // line-height: 40px;
  color: #fff;
  font-size: 16px;
  // margin: 4% 42% 1%;
  margin-right: 20px;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
}
.imgs {
  position: relative;
  display: flex;
  justify-content: center;
  img {
    // position: absolute;
    // top: -20px;
  }
}
.but {
  .el-button {
    background-image: linear-gradient(to bottom, #b14a75, #b14a75);
    color: #fff;
    width: 98px;
    border-radius: 3px;
  }
  /deep/ .el-button.is-round {
    border-radius: none !important;
  }
}
.el-dropdown {
  /deep/ .el-button-group {
    .el-button {
      //  background: #67c0db !important;
      //  border:none;
      span {
        span {
          display: inline-block;
          width: 35px;
        }
      }
    }
  }
}
.standard {
  /deep/ .el-button-group {
    .el-button {
      background: #d973d9 !important;
      border: none;
    }
  }
}
.patent {
  /deep/ .el-button-group {
    .el-button {
      background: #a673d9 !important;
      border: none;
    }
  }
}
.enBook {
  /deep/ .el-button-group {
    .el-button {
      background: #d9d973 !important;
      border: none;
    }
  }
}
.PMCID {
  /deep/ .el-button-group {
    .el-button {
      background: #73d9a6 !important;
      border: none;
    }
  }
}
.PMID {
  /deep/ .el-button-group {
    .el-button {
      background: #73d973 !important;
      border: none;
    }
  }
}
.Url {
  /deep/ .el-button-group {
    .el-button {
      background: #73d9d9 !important;
      border: none;
    }
  }
}
.Doi {
  /deep/ .el-button-group {
    .el-button {
      background: #8676bd !important;
      border: none;
    }
  }
}
.enDoc {
  /deep/ .el-button-group {
    .el-button {
      background: #7373d9 !important;
      border: none;
    }
  }
}
.zhDoc {
  /deep/ .el-button-group {
    .el-button {
      background: #73a6d9 !important;
      border: none;
    }
  }
}
.zhBook {
  /deep/ .el-button-group {
    .el-button {
      background: #d9a673 !important;
      border: none;
    }
  }
}
.Other {
  /deep/ .el-button-group {
    .el-button {
      background: #d973a6 !important;
      border: none;
    }
  }
}
.null {
  /deep/ .el-button-group {
    .el-button {
      background: #d973a6 !important;
      border: none;
    }
  }
}
/deep/.el-table__header-wrapper .el-checkbox {
  display: none;
}
// .highlightInput::-webkit-scrollbar-thumb
/deep/ .el-textarea__inner::-webkit-scrollbar {
  width: 10px;
}
/* 滚动槽 */
/deep/ .el-textarea__inner::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 1);
  border-radius: 10px;
  background: rgb(197, 197, 197);
}
/* 滚动条滑块 */
/deep/ .el-textarea__inner::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
/deep/ .el-textarea__inner::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, 0.4);
}
.highlightInput::-webkit-scrollbar {
  width: 10px;
}
/* 滚动槽 */
.highlightInput::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 1);
  border-radius: 10px;
  background: rgb(197, 197, 197);
}
/* 滚动条滑块 */
.highlightInput::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
.highlightInput::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, 0.4);
}
.btn :hover {
  // background: rgb(240, 237, 237, 0.5);
}
.edit_suc {
  padding: 7px 5px;
  border: none;
  background: none;
  font-size: 15px;
}
.edit_suc:hover {
  color: #7373d9;
  background: none;
}
.note_fu {
  // padding-top: 50px;
  display: flex;
  justify-content: center;
  margin: 20px 110px 20px 80px;
  .note_fu01 {
    width: 100%;
    // max-width: 1070px;
    // margin: 30px auto !important;
  }
}
/deep/ .el-divider {
  // min-width: 800px;
  margin: 0 !important;
  //   width: 90% !important;
}
.note {
  //   width: 90%;
  //    min-width: 800px;
  padding: 10px 30px;
  border-left: 1px #dcdfe6 solid;
  border-right: 1px #dcdfe6 solid;
  border-bottom: 1px #dcdfe6 solid;
  p {
    line-height: 25px;
    display: flex;
    margin: 10px 0;
    // min-width: 1000px;
    img {
      margin: 0 20px;
      width: 12px;
      height: 12px;
      position: relative;
      top: 8px;
    }
    span {
      word-break: normal;
      //   width: 500px;
      display: inline-block;
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow: hidden;
      .col {
        position: relative;
        top: 7px;
      }
      .top {
        font-size: 5px;
        position: relative;
        top: -8px;
        left: -3px;
        font-style: normal;
      }
    }
  }
}
.notices {
  line-height: 50px;
  display: flex;
  margin: 10px 0;
  // min-width: 1000px;
  img {
    margin: 0 20px;
    width: 12px;
    height: 12px;
    position: relative;
    top: 20px;
  }
  span {
    word-break: normal;
    //   width: 500px;
    display: block;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
    .top {
      font-size: 5px;
      position: relative;
      top: -8px;
      left: -3px;
      font-style: normal;
    }
  }
}
.textarea_css_remark {
  /deep/ .el-textarea__inner {
    resize: none;
  }
}

</style>
<style scoped >
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;

  /* background: rgb(255, 92, 92,0.5); */
}
/deep/.el-upload {
  width: 100% !important;
  height: 196px !important;
}
/deep/.el-upload-dragger {
  width: 100% !important;
  height: 196px !important;
}
/* .el-button{
    background-color: #b14a75 ;
    color: #fff;
  } */
.text_edit {
  margin: 0 0 0 10px;
}
.text_edit:hover {
  color: rgb(161, 121, 255);
  cursor: pointer;
}
.tag_hover:hover {
  cursor: pointer;
}
.addRemark:hover {
  color: rgb(161, 121, 255);
  cursor: pointer;
}

</style>