<template>
    <div style="background: white; display: flex; justify-content: center">
        <!-- <div class="tool_box" v-bind:style="{ minHeight: Height + 'px' }">
      
      <div>
        <div class="one">
          <el-card shadow="always">
            <img
              style=""
              src="../.../../../../assets/imgs/logo/dklogo.png"
              alt=""
              @click="opeen('xdf')"
            />
            
            <p>
              <a href="http://www.daokeyuedu.com/" target="_blank">xdf阅读器</a>
            </p>
          </el-card>

          <el-card shadow="always">
            <img
              @click="opeen('epub')"
              style=""
              src="../.../../../../assets/imgs/logo/wps.png"
              alt=""
            />
           
            <p>
              <a href="https://platform.wps.cn/" target="_blank">epub阅读器</a>
            </p>
          </el-card>
          <el-card shadow="always">
            <img
              style=""
              src="../.../../../../assets/imgs/logo/neat.png"
              alt=""
              @click="opeen('mobi')"
            />
            <p>
              <a href="https://www.neat-reader.cn/" target="_blank"
                >mobi阅读器</a
              >
            </p>
          </el-card>
          <el-card shadow="always" class="djvu">
           
            <img
              style=""
              src="../.../../../../assets/imgs/logo/windjview-logo.png"
              alt=""
              @click="opeen('djvu')"
            />
          
            <p>
              <a href="https://windjview.en.softonic.com/" target="_blank"
                >djvu阅读器</a
              >
            </p>
          </el-card>
          <el-card shadow="always">
          <img
            style=""
            src="../.../../../../assets/imgs/logo/Adobe.png"
            alt=""
            @click="opeen('pdf1')"
          />
         
          <p>
            <a href="https://www.adobe.com/cn/acrobat.html" target="_blank"
              >pdf阅读器一</a
            >
          </p>
        </el-card>
        <el-card shadow="always">
          <img
            style=""
            src="../.../../../../assets/imgs/logo/fox.png"
            @click="opeen('pdf2')"
            alt=""
          /> 
          <p>
            <a href="https://www.foxitsoftware.cn/downloads/" target="_blank"
              >pdf阅读器二</a
            >
          </p>
        </el-card>
        </div>
        <div class="two">
          <el-card shadow="always">
            
            <img
              style=""
              src="../.../../../../assets/imgs/logo/caj.png"
              alt=""
              @click="opeen('caj')"
            />
           
            <p>
              <a href="https://cajviewer.cnki.net/index.html" target="_blank"
                >caj阅读器</a
              >
            </p>
          </el-card>
        </div>
        <h2 style="line-height: 50px">pdf阅读器</h2>
        <div class="one">
        
          <el-card shadow="always">
            <img
              style=""
              src="../.../../../../assets/imgs/logo/Adobe.png"
              alt=""
              @click="opeen('pdf1')"
            />
            
            <p>
              <a href="https://www.adobe.com/cn/acrobat.html" target="_blank"
                >pdf阅读器一</a
              >
            </p>
          </el-card>
          <el-card shadow="always">
            <img
              style=""
              src="../.../../../../assets/imgs/logo/fox.png"
              @click="opeen('pdf2')"
              alt=""
            />
           
            <p>
              <a href="https://www.foxitsoftware.cn/downloads/" target="_blank"
                >pdf阅读器二</a
              >
            </p>
          </el-card>
        </div>
      </div>
    </div> -->
        <div v-bind:style="{ minHeight: Height + 'px' }" style="padding: 20px 0">
            <!-- <div class="imgs"></div> -->
            <ul>
                <li>
                    <div class="box">
                        <div class="imgs">
                            <img style="
                  transform: scale(0.7);
                  width: 120px;
                  height: 120px;
                  margin-right: 80px;cursor: pointer;
                " src="../.../../../../assets/imgs/logo/ku_logo.png" alt="" @click="opeen('cruel')" />
                        </div>

                        <div class="name">

                            <h2>酷学术</h2>
                            <h3>
                                智能科研利器，具有语义文献推荐、在线即时翻译、网页智能问答等多种研学辅助功能，适用于多种研学场景。
                            </h3>
                        </div>
                        <div>学术类工具</div>
                        <!-- <div>2022年06月27日</div> -->
                        <el-button type="primary" @click="opeen('cruel')">下载链接</el-button>
                    </div>
                    <el-divider></el-divider>
                </li>
                <li>
                    <div class="box">
                        <div class="imgs">
                            <img style="cursor: pointer;" src="../.../../../../assets/imgs/logo/Adobe.png" alt=""
                                @click="opeen('pdf1')" />
                        </div>

                        <div class="name">
                            <h2>adobe Acrobat</h2>
                            <h3>
                                多功能PDF软件，用于创建、编辑、查看和签署PDF文件，同时支持表单填写和安全文件共享。
                            </h3>
                        </div>
                        <div>pdf阅读器</div>
                        <!-- <div>2022年06月27日</div> -->
                        <el-button type="primary" @click="opeen('pdf1')">下载链接</el-button>
                    </div>
                    <el-divider></el-divider>
                </li>
                <li>
                    <div class="box">
                        <div class="imgs">
                            <img style="cursor: pointer;" src="../.../../../../assets/imgs/logo/fox.png" alt=""
                                @click="opeen('pdf2')" />
                        </div>

                        <div class="name">
                            <h2>褔盺阅读器</h2>
                            <h3>轻量级PDF文档阅读器，提供丰富的PDF查看和注释功能。</h3>
                        </div>
                        <div>pdf阅读器</div>
                        <!-- <div>2022年06月27日</div> -->
                        <el-button type="primary" @click="opeen('pdf2')">下载链接</el-button>
                    </div>
                    <el-divider></el-divider>
                </li>
                <li>
                    <div class="box">
                        <div class="imgs">
                            <img style="cursor: pointer;" src="../.../../../../assets/imgs/logo/neat.png" alt=""
                                @click="opeen('mobi')" />
                        </div>

                        <div class="name">
                            <h2>Neat Reader</h2>
                            <h3>电子书阅读器，支持EPUB和MOBI等多种格式，提供流畅的阅读体验和书籍管理功能。</h3>
                        </div>
                        <div>mobi阅读器</div>
                        <!-- <div>2022年06月27日</div> -->
                        <el-button type="primary" @click="opeen('mobi')">下载链接</el-button>
                    </div>
                    <el-divider></el-divider>
                </li>
                <li>
                    <div class="box">
                        <div class="imgs">
                            <img style="
                  transform: scale(0.7);
                  width: 120px;
                  height: 120px;
                  margin-right: 80px;cursor: pointer;
                " src="../.../../../../assets/imgs/logo/windjview-logo.png" alt="" @click="opeen('djvu')" />
                        </div>

                        <div class="name">
                            <h2>WinDjView</h2>
                            <h3>
                                用于浏览DjVu文件格式的Windows阅读器，主要用于查看和打印DjVu文档。
                            </h3>
                        </div>
                        <div>djvu阅读器</div>
                        <!-- <div>2022年06月27日</div> -->
                        <el-button type="primary" @click="opeen('djvu')">下载链接</el-button>
                    </div>
                    <el-divider></el-divider>
                </li>
                <li>
                    <div class="box">
                        <div class="imgs">
                            <img style="cursor: pointer;" src="../.../../../../assets/imgs/logo/dklogo.png" alt=""
                                @click="opeen('xdf')" />
                        </div>

                        <div class="name">
                            <h2>稻壳阅读器</h2>
                            <h3>PDF阅读和编辑工具，提供文档处理和多种文件格式转换等功能。</h3>
                        </div>
                        <div>xdf、epub阅读器</div>
                        <!-- <div>2022年06月27日</div> -->
                        <el-button type="primary" @click="opeen('xdf')">下载链接</el-button>
                    </div>
                    <el-divider></el-divider>
                </li>
                <li>
                    <div class="box">
                        <div class="imgs">
                            <img src="../.../../../../assets/imgs/logo/caj.png" alt="" @click="opeen('caj')"
                                style="cursor: pointer;" />
                        </div>

                        <div class="name">
                            <h2>CAJViewer 7.3</h2>
                            <h3>
                                主要用于查看CAJ和NH文件格式的文档，应用于学术论文的阅读。
                            </h3>
                        </div>
                        <div>caj阅读器</div>
                        <!-- <div>2022年06月27日</div> -->
                        <el-button type="primary" @click="opeen('caj')">下载链接</el-button>
                    </div>
                    <el-divider></el-divider>
                </li>
            </ul>
            <h3 class="prompt">更多工具仅在网页版提供</h3>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            Height: 0,
        };
    },
    mounted() {
        //动态设置内容高度 让footer始终居底   header+footer的高度是190
        // console.log(this.name)
        this.Height = document.documentElement.clientHeight - 80; //监听浏览器窗口变化
        window.onresize = () => {
            this.Height = document.documentElement.clientHeight - 80;
        };
    },
    created() { },
    methods: {
        opeen(word) {
            if (word == "caj") {
                window.open("https://cajviewer.cnki.net/index.html", "_blank");
            }
            if (word == "djvu") {
                window.open("https://windjview.sourceforge.io/en/", "_blank");
            }
            if (word == "mobi") {
                window.open("https://www.neat-reader.cn/", "_blank");
            }
            if (word == "pdf2") {
                window.open("https://www.foxitsoftware.cn/downloads/", "_blank");
            }
            if (word == "pdf1") {
                window.open("https://www.adobe.com/cn/acrobat.html", "_blank");
            }
            if (word == "epub") {
                window.open("https://platform.wps.cn/", "_blank");
            }
            if (word == "xdf") {
                window.open("http://www.daokeyuedu.com/", "_blank");
            }
            if (word == "cruel") {
                window.open("http://addons.dic.cool/", "_blank");
            }
        },
    },
};
</script>

<style lang="less" scoped>
.prompt {
    text-align: center;
    // color: rgb(214, 214, 214);
}

.tool_box {
    width: 100%;
    height: 100px;
    background: white;
    padding: 20px;
    display: flex;
    justify-content: center;

    .one {
        width: 100%;
        display: flex;
        //   justify-content: space-between;
        margin: 0 30px 0 0;
    }

    .two {
        //   width: 50%;
        display: flex;
        margin: 0 30px 0 0;
        //   justify-content: space-between;
    }

    //   justify-content: space-around;

    //   .tools {
    //     width: 300px;
    //     box-shadow: 0px -1px 0px 0px #e5e5e5,
    //       /*上边阴影 */ -0.2px 0px 0px 0px #e5e5e5,
    //       /*左边阴影  */ 0.2px 0px 0px 0px #e5e5e5,
    //       /*右边阴影 */ 0px 1px 0px 0px #e5e5e5; /*下边阴影 */

    //     img {
    //       margin: 0 50px;
    //       width: 200px;
    //     }
    //     p {
    //       line-height: 30px;
    //       text-align: center;
    //       font-size: 18px;
    //     }
    //     p:hover {
    //       color: rgb(142, 201, 241);
    //       cursor: pointer;
    //     }
    //   }
    .el-card {
        width: 300px;
        height: 120px;
        margin: 10px;

        img {
            width: 200px;
            height: 40px;
            margin: 0 30px;
        }

        p {
            line-height: 30px;
            text-align: center;
            font-size: 18px;

            a {
                font-size: 15px;
            }

            a:hover {
                color: rgb(142, 201, 241);
                cursor: pointer;
            }
        }

        p:hover {
            //   color: rgb(142, 201, 241);
            //   cursor: pointer;
        }

        h2 {
            line-height: 40px;
            font-size: 20px;
            font-weight: bold;
            color: #42a5f5;
        }
    }

    .djvu {
        display: flex;

        .imgs {
            width: 220px;
            margin-right: 30px;
        }

        img {
            transform: scale(0.7);
            width: 120px;
            height: 120px;
            position: relative;
            top: -20px;
            left: -30px;
        }

        p {
            display: inline-block;
            position: relative;
            top: -80px;
            left: -50px;
        }
    }
}

.tag {
    position: relative;
    // top:-50px ;
    right: -70px;
    // z-index: 500;
}

ul {
    li {
        // margin: 0 60px;
        width: 1000px;
    }
}

.box {
    margin: 0 20px;
    display: flex;
    //   justify-content: space-between;
    align-items: center;

    .imgs {
        width: 220px;
        margin-right: 30px;
    }

    img {
        width: 200px;
        height: 40px;
    }

    div {
        min-width: 120px;
    }

    .name {
        //   margin: 0 20px;
        width: 500px;
        margin: 0 20px;

        h2 {
            font-weight: 13x;
            font-weight: 100;
            line-height: 30px;
        }

        h3 {
            font-weight: 10px;
            color: rgb(182, 182, 182);
            font-weight: 100;
            line-height: 20px;
        }
    }

    .el-button {
        margin: 0 20px;
    }
}
</style>